import { EventEmitter, Injectable, Output } from "@angular/core";
import { ApiUrl } from "@core/consts/api-url";
import { ApiService } from "@core/http/api.service";
import { map } from "rxjs/operators";
import { DateService } from "./date.service";

@Injectable({ providedIn: "root" })
export class PnlService {
  private _fromDate: Date;
  private _toDate: Date;
  @Output() dateUpdate = new EventEmitter<void>();

  constructor(
    private apiService: ApiService,
    private dateService: DateService
  ) {}

  get FromDate() {
    return this._fromDate;
  }

  set FromDate(value: Date) {
    this._fromDate = value;
  }

  get ToDate() {
    return this._toDate;
  }

  set ToDate(value: Date) {
    this._toDate = value;
  }

  getCustomerProfit(reportDateType: number, fromDate?: Date, toDate?: Date) {
    const fromDateString = this.dateService.dateToApiFormat(fromDate);
    const toDateString = this.dateService.dateToApiFormat(toDate);
    return this.apiService
      .get<any>(
        `${ApiUrl.customerProfit}?ReportDateType=${reportDateType}&FromDate=${fromDateString}&ToDate=${toDateString}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getWalletProfit(
    reportDateType: number,
    fromDate: Date,
    toDate: Date,
    size = 1000
  ) {
    const fromDateString = this.dateService.dateToApiFormat(fromDate);
    const toDateString = this.dateService.dateToApiFormat(toDate);
    return this.apiService
      .get<any>(
        `${ApiUrl.walletProfit}?ReportDateType=${reportDateType}&FromDate=${fromDateString}&ToDate=${toDateString}&Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getCustomerAssets() {
    return this.apiService.get<any>(ApiUrl.customerAssets).pipe(
      map((response) => {
        return response?.Result;
      })
    );
  }
}
